import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { GET_TRANSLATION_URL } from './components/App/AppSettings';
import { fetchRequest, getParams } from './components/Shared/Actions/';
import { ERROR } from './components/Shared/Constants/LanguageKeys';
import { toastError } from './components/Shared/Forms';

const loadCurrent = (options, url, payload, callback) => {
    fetchRequest(url, getParams(), false)
        .then(response => {
            if (response.success && response.body.Data) {
                const data = response.body.Data.Translation;
                    callback(null, {status: response.status, data: data});
            } else {
                toastError(i18n.t(ERROR.TRANSLATION_LOAD_ERROR))
            }
        })
};


i18n
    .use(Backend)
    .use(initReactI18next)// passes i18n down to react-i18next
    .init({
        backend: {
            loadPath: GET_TRANSLATION_URL,
            request: loadCurrent,
        },
        lng: 'en',
        load: 'currentOnly',
        lowerCaseLng: true,
        fallbackLng: 'en',
        parseMissingKeyHandler: () => {
            return '';
        },
        debug: true,
        react: {
            useSuspense: true,
            bindI18nStore: 'added'
        },
    });

export default i18n;
